<script setup lang="ts">
import { inject, onMounted } from 'vue';
import TheUserMenu from './components/TheUserMenu.vue';
import TheVersionTag from './components/TheVersionTag.vue';
import branch from '@/composables/branch';

import { useAuthStore } from './stores/authStore';
import { useAuthUtils } from '@/utilities/authUtils';

import { useGeneralStore } from './stores/generalStore';
import i18nCDN from '@/utilities/i18nCDN';
import apiCall from './api/apiCall';
import { useGlobalRouter } from '@/router/globalRouterInstance';
import { useRouter } from 'vue-router';
//NOTE - setGlobalt is a method that sets up a const 'globalt' to use the t method inside modules. Needs to be initialized in a setup function.
import { setGlobalt } from '@/assets/i18n/globalt';
setGlobalt();

const { gRouter } = useGlobalRouter();
const router = useRouter();

gRouter.value = router;
const generalStore = useGeneralStore();
const authStore = useAuthStore();
const { logOut } = useAuthUtils();

const config = inject<{ locale: string }>(Symbol.for("FormKitConfig"));

onMounted(async () => {
  const { url: metaUrl } = await apiCall('/localazy', 'get', null, null);
  let metaFile: any;
  if (metaUrl) {
    const res = await fetch(metaUrl);
    metaFile = await res.json();
  }

  if (import.meta.env.VITE_X_BRANCH !== 'production') {
    await i18nCDN.getLocaleFromCDN('nl', metaFile ? metaFile : null);
    await i18nCDN.getLocaleFromCDN('en', metaFile ? metaFile : null);
  }
  generalStore.config = config;
  //NOTE - Set formkit locale to equal global locale. Required to prevent bugs with router guards.
  if (generalStore.config) {
    generalStore.config.locale = generalStore.locale
  }
})

</script>

<template>
  <TheUserMenu @log-out="logOut" v-if="authStore.user.id" />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <TheVersionTag v-if="branch !== 'production'" />
</template>

<style>
#app {
  width: 100%;
  height: 100%;
}

.user-menu {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.c-versiontag {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
}
</style>
